import React, { Component } from 'react';
import {
    Row,
    Col,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle
} from 'reactstrap';
import { Link } from "react-router-dom";


class Nuts extends Component {

    render() {

        return (
            <div className="mc-nuts">
                <Row>
                    <Col>
                        <h2 style={{ marginTop: '20px' }}>Go nuts!</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/ialIBbplr0Y" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </p>
                        <p>
                            Hitta på något och bygg det!
                        </p>
                    </Col>
                </Row>
            </div >
        );
    }
}

export default Nuts;
