import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Link } from "react-router-dom";


class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div style={{ marginBottom: '20px' }}>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/"><h1>system.hack() - Arduino Night </h1></NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link class="nav-link" to="/">Start</Link>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Projekt
                </DropdownToggle>
                <DropdownMenu right>
                  <Link to="/touch">
                    <DropdownItem>
                      Don't touch
                  </DropdownItem>
                  </Link>
                  <Link to="/thief">
                    <DropdownItem>
                      Like a thief
                  </DropdownItem>
                  </Link>
                  <Link to="/nuts">
                    <DropdownItem>
                      Go nuts!
                  </DropdownItem>
                  </Link>
                  <DropdownItem divider />
                  <Link to="/sensors">
                    <DropdownItem>
                      Sensors
                  </DropdownItem>
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Main;
