import React, { Component } from 'react';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button, Row, Col
} from 'reactstrap';
import './index.css';


class Sensors extends Component {
  render() {
    return (
      <div className="mc-sensors">
      <h2 style={{marginBottom: '20px'}}>
        Några (totalt 28st) av de sensorer vi kan leka med:
      </h2>
        <Row>
          <Col>
            <Card md="4">
              <CardImg top src="https://www.circuitmagic.com/wp-content/uploads/2015/12/2016-03-08_1135212.jpg" alt="Card image cap" />
              <CardBody>
                <CardTitle>PIR motion Sensor</CardTitle>
                <CardSubtitle>Läser rörelse på ett stort område!</CardSubtitle>
                <CardText></CardText>
                <a href="https://www.circuitmagic.com/arduino/pir-motion-sensor-with-arduino/" target="_blank">Gå till guide</a>
              </CardBody>
            </Card>
          </Col>

          <Col>
            <Card md="4">
              <CardImg top src="https://www.electrokit.com/uploads/productimage/41013/41013207.jpg" alt="Card image cap" />
              <CardBody>
                <CardTitle>Avståndsmätare ultraljud HC-SR04 2 – 400cm</CardTitle>
                <CardSubtitle>Mäter avstånd med ultraljud!</CardSubtitle>
                <CardText></CardText>
                <a href="https://medium.com/@seyoum14/using-a-hy-sr05-sensor-to-measure-distance-with-arduino-88e34d09c1c7" target="_blank">Gå till guide</a>
              </CardBody>
            </Card>
          </Col>

          <Col>
            <Card md="4">
              <CardImg top src="https://hackster.imgix.net/uploads/attachments/393419/how-to-use-sound-sensor-iotboys_5QE2YvIbVo.png?auto=compress%2Cformat&w=900&h=675&fit=min" alt="Card image cap" />
              <CardBody>
                <CardTitle>Ljudsensor</CardTitle>
                <CardSubtitle>"The clapper"</CardSubtitle>
                <CardText>"The clapper" tittar ljud på/av, kan du möjligen använda analoga outputen istället för att mäta av volym och kanske göra blinkande lampor synkat till volymen i en låt? <br />
                  analogRead(MIC) för att läsa av analoga output.
                </CardText>
                <a href="https://create.arduino.cc/projecthub/iotboys/control-led-by-clap-using-arduino-and-sound-sensor-e31809" target="_blank">Gå till guide (ljud eller ej)</a>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>

            <Card>
              <CardImg top src="http://arduinolearning.com/wp-content/uploads/2016/07/ky-032jpg.jpg" alt="Card image cap" />
              <CardBody>
                <CardTitle>IR obstacle avoidance sensor </CardTitle>
                <CardSubtitle></CardSubtitle>
                <CardText></CardText>
                <a href="http://arduinolearning.com/code/ir-obstacle-avoidance-sensor-example.php" target="_blank">Gå till guide</a>
              </CardBody>
            </Card>

          </Col>

          <Col>
            <Card>
              <CardImg top src="https://ae01.alicdn.com/kf/HTB14YruNpXXXXbGXpXXq6xXFXXXi/Knock-Sensor-Module-FOR-ARDUINO.jpg_640x640.jpg" alt="Card image cap" />
              <CardBody>
                <CardTitle>Knock Sensor </CardTitle>
                <CardSubtitle></CardSubtitle>
                <CardText></CardText>
                <a href="http://www.himix.lt/arduino/arduino-and-shock-knock-sensor-ky-031/" target="_blank">Gå till guide</a>
              </CardBody>
            </Card>
          </Col>

          <Col>
            <Card>
              <CardImg top src="https://hackster.imgix.net/uploads/attachments/329816/img_2427_IR9IF32YNW.JPG?auto=compress%2Cformat&w=900&h=675&fit=min" alt="Card image cap" />
              <CardBody>
                <CardTitle>Du ger mig bra vibrationer</CardTitle>
                <CardSubtitle></CardSubtitle>
                <CardText></CardText>
                <a href="https://www.youtube.com/watch?v=mxQZfroLPmk" target="_blank">Gå till guide</a>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </div>
    );
  }
}

export default Sensors;
