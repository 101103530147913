import React, { Component } from 'react';
import './Main.css';
import Header from './../../components/Header'
import { Route } from 'react-router-dom';
import TransitionSwitch from 'react-router-transition-switch'
import Fader from 'react-fader'
import Start from './../Start';
import Touch from './../Touch';
import Sensors from './../Sensors';
import Thief from './../Thief';
import Nuts from './../Nuts';
import { Container } from 'reactstrap';


class Main extends Component {
  render() {
    return (
      <div className="mc-main">
        <Header />
        <Container>
          <TransitionSwitch component={Fader}>
            <Route exact path="/" component={Start} />
            <Route exact path="/touch" component={Touch} />
            <Route exact path="/sensors" component={Sensors} />
            <Route exact path="/thief" component={Thief} />
            <Route exact path="/nuts" component={Nuts} />
          </TransitionSwitch>
        </Container>
      </div>
    );
  }
}

export default Main;
