import React, { Component } from 'react';
import {
    Row,
    Col,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle
} from 'reactstrap';
import { Link } from "react-router-dom";


class Thief extends Component {

    render() {

        return (
            <div className="mc-thief">
                <Row>
                    <Col>
                        <h2 style={{marginTop: '20px'}}>Like a thief!</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card >
                            <CardImg top src="https://static.independent.co.uk/s3fs-public/thumbnails/image/2018/06/29/10/indiana-jones.jpg?w968" alt="Card image cap" />
                            <CardBody>
                                <CardTitle>I den här utmanningen skall ert team skydda en skatt!</CardTitle>
                                <CardSubtitle></CardSubtitle>
                                <CardText>
                                    <p>
                                        Bestäm vad skatten skall vara och inom vilket område den skall befinna sig.
                                    </p>
                                    <p>
                                        Använd sensorer för att skydda skatten.
                                    </p>
                                    <p>
                                        + poäng för kreativitet samt möjlighet för Indiana Jones att sa sig igenom (Gåtor eller sätt att kringå sensorn/erna).
                                    </p>
                                </CardText>
                                <Link to="/sensors">Några sensorer listas här</Link>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >
        );
    }
}

export default Thief;
