import React, { Component } from 'react';
import {
    Row,
    Col,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, ListGroup, ListGroupItem
} from 'reactstrap';
import './index.css';
import HANDLE from './handle.jpg';
import NEED from './need.jpeg';
import KOP1 from './koppling1.png';
import CODE1 from './code1.png';
import BUZZER from './buzzer.png';
import BINERY from './binery.jpg';

const items = [
    {
        src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
        altText: 'Slide 1',
        caption: 'Slide 1'
    },
    {
        src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa20%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa20%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3203125%22%20y%3D%22218.3%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
        altText: 'Slide 2',
        caption: 'Slide 2'
    },
    {
        src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
        altText: 'Slide 3',
        caption: 'Slide 3'
    }
];

class Touch extends Component {

    render() {

        return (
            <div className="mc-touch">
                <Row>
                    <Col>
                        <h2>Buzz wire game!</h2>
                    </Col>
                </Row>
                <Row>
                    <div className="col">
                        <iframe title="Buzz demo" width="560" height="315" src="https://www.youtube.com/embed/xLchyHikDeA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </Row>
                <Row>
                    <Col>
                        <p>
                            Målet här är att bygga ett "buzz wired game", där banan är lång och svår
                            så de andra lagen inte tar sig igenom spelet oskadda!

                            Idéen bakom spelet är väldigt enkel och själv utmanningen ligger
                            i hur kan du/ni skapa en riktigt intressant bana (fantasi).
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col md="4">
                        <Card>
                            <CardImg top
                                width="100%"
                                src={NEED}
                                alt="What you need" />
                            <CardBody>
                                <CardText>
                                    <ListGroup>
                                        <ListGroupItem>Ståltråd</ListGroupItem>
                                        <ListGroupItem>1 Arduino enhet</ListGroupItem>
                                        <ListGroupItem>2 x 220ohm resistorer</ListGroupItem>
                                        <ListGroupItem>1 kopplingsbräda (breadboard)</ListGroupItem>
                                        <ListGroupItem>1 buzzer (högtalare)</ListGroupItem>
                                        <ListGroupItem>x Kablar (hane-hane, hona-hane)</ListGroupItem>
                                        <ListGroupItem>Fantasi</ListGroupItem>
                                    </ListGroup>
                                </CardText>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <h3>Steg 1</h3>

                                <p>"Handtaget", här skapat av ståltråd + delar av en bläckpenna</p>

                                <img
                                    width="50%"
                                    src={HANDLE}
                                    alt="Handtaget" />

                                <p>Som kan ses i filmen så kan "handtaget" ha en ögla som inte
                                är helt sluten. Detta för att möjliggöra större frihet i banbygget,
                                    hinder och upphängningsanorndingar kan då kopplas på banan.</p>
                                <p>Fantasin sätter gränserna!</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h3>Steg 2</h3>

                                <p>Koppla ihop banan samt handtaget med enheten.</p>

                                <img
                                    width="100%"
                                    src={KOP1}
                                    alt="koppling 1" />

                                <p>
                                    Spelaren fullbordar kretsen när handtaget berör banan. <br/>
                                    <b>Se till att du inkluderar båda motstånden (2x220ohm).</b>
                                    <br/><br/>
                                    Ett motstånd knyter kretsen till marken (-), 
                                    vilket gör att kretsen inte är "flytande" 
                                    (detta gör det möjligt för Arduino att upptäcka att kretsen ändras). 
                                    Det andra motståndet skyddar Arduino enheten, eftersom 
                                    när de två delarna berörs, går + 5V in i digital-in. 
                                    Om detta motstånd saknas blir det en död krets och risken finns 
                                    att din dator kopplar ifrån USB porten.
                                </p>

                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <h3>Steg 3</h3>

                                <p>Koda och testa att banan fungerar!</p>

                                <img
                                    width="100%"
                                    src={CODE1}
                                    alt="koppling 1" />
                                <br/><br/>

                                <ol>
                                    <li>Port 9 konfigureras för input (pinMode)</li>
                                    <li>Ifall kretsen är sluten (digitalRead(HANDLE)) och INTERVAL har paserat sen sist
                                        Skriv ut "AJAJ ..." 
                                    </li>
                                </ol>

                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <h3>Steg 4</h3>

                                <p>Gör ett ljud när man nuddar banan</p>

                                <img
                                    width="100%"
                                    src={BUZZER}
                                    alt="koppling 1" />
                                <br/><br/>

                                <p>
                                    Du hittar en Buzzer (högtalare) i kjell kitet.
                                    <br /> för att spela ljud behövs den kopplas in enligt <br />
                                    en output-pin på Arduinon till + på buzzer. Samt - till GND.<br />
                                    <br/>
                                    För att spela ljud kan: tone(2, 784, 500); användas
                                    <br/>
                                    <i>PIN, TON, LÄNGD</i>
                                </p>

                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <h3>Steg 5</h3>

                                <p>Vidareutvecklings förslag (men sen får du/ni stilpoäng om du gör mer!)</p>

                                <img
                                    width="100%"
                                    src={BINERY}
                                    alt="make more" />
                                <br/><br/>

                                <p>
                                    Kan du/ni lösa så antal "fails"  visas binert med LED's ?
                                </p>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div >
        );
    }
}

export default Touch;
