import React, { Component } from 'react';


class Start extends Component {
  render() {
    return (
      <div className="mc-start" style={{paddingTop: '20px', paddingBottom: '40px'}}>
        <h2>System.hack() <br />
        24/4 klockan 17:00</h2>

        <p>
          Vi börjar kvällen med att se hur arbetet med en av idéerna <i>(kunna tracka vad som finns i kylen)</i> som kom in under PVB kursen i vår har levt vidare under året.
          <br/>
          Då det finns nu prototyper för backend, frontend (webb samt Android appar). Samt en fysikenhet som kan läsa av sträckkoder.
        </p>

        <p>
          Det finns sedan möjlighet att bygga med Aurdino! <br/>
          Förslag på byggen hittas under <b>Projekt</b> i menyn.
        </p>

        <p>
          <b>Gemensam beställning ifrån <a href="https://marco-polo.se/" target="_blank"> Marco Polo </a> under kvällen för de intresserade!</b> <br/> Betalning med Swish
        </p>

      </div>
    );
  }
}

export default Start;
